import React from "react"
import { Link } from 'gatsby'
import Layout from '../containers/Layout/Layout'
import Seo from '../components/Seo'
import ServicesCoverImage from '../assets/images/service-cover.png'
import PageCover from '../components/PageCover'

const PageNotFound = () => {
  return (
    <Layout>
      <Seo title="Page Not Found" />
      <PageCover
        imageSrc={ServicesCoverImage}
        coverTitle="Page Not Found"
      >
        <Link to="/">Go Back Home</Link>
      </PageCover>
    </Layout>
  )
}

export default PageNotFound
